import './index.scss'
import React from 'react'
import LogoS from '../../assets/images/logo.svg'
import { Link, NavLink } from 'react-router-dom'
import Name from '../../assets/images/name.svg'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'

const Sidebar = () => {
  return (
    <div className="nav-bar">
      <Link className="logo" to="/">
        <img src={LogoS} color="purple" alt="logo" />
        <img className="sub-logo" src={Name} alt="name" />
      </Link>
      <nav>
        <NavLink exact="true" activeclassname="active" to="/">
          <HomeIcon />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="about-link"
          to="/about"
        >
          <PersonIcon />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="/contact"
        >
          <EmailIcon />
        </NavLink>
      </nav>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/hector-garza-54144523b/"
          >
            <LinkedInIcon />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/SmartXchain"
          >
            <GitHubIcon />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/HectorG19194757"
          >
            <TwitterIcon />
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://instagram.com">
            <InstagramIcon />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar
