import React from 'react'
import './index.scss'
import LogoS from '../../../assets/images/logo.svg'

const Logo = () => {
  return (
    <div className="logo-container">
      <img className="solid-logo" src={LogoS} alt="X" />
    </div>
  )
}

export default Logo
