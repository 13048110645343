import React, { useEffect, useState } from 'react'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import PsychologyIcon from '@mui/icons-material/Psychology'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import TerminalIcon from '@mui/icons-material/Terminal'
import JavascriptIcon from '@mui/icons-material/Javascript'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import DataObjectIcon from '@mui/icons-material/DataObject'
import Loader from 'react-loaders'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'U', 's']}
              idx={15}
            />
          </h1>
          <h2>
            We are ambitious technology developers looking for manufacturers
            involved in servicing critical components for the military services.
            We bring AI, blockchain, and IoT technologies to the supply chain.
          </h2>
          <h2>
            We are constantly working on improving the supply chain for all
            services.
          </h2>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="top">
              <PsychologyIcon fontSize="100px" color="primary" alt="AI" />
            </div>
            <div className="right">
              <CloudSyncIcon fontSize="100px" color="action" alt="Cloud" />
            </div>
            <div className="bottom">
              <TerminalIcon fontSize="100px" color="secondary" alt="Complex" />
            </div>
            <div className="left">
              <JavascriptIcon fontSize="100px" color="warning" alt="Embedded" />
            </div>
            <div className="front">
              <PrecisionManufacturingIcon
                fontSize="100px"
                color="info"
                alt="Flex"
              />
            </div>
            <div className="back">
              <DataObjectIcon fontSize="100px" color="disabled" alt="Logic" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
