import React, { useEffect, useState } from 'react'
import './index.scss'
import LogoS from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import Loader from 'react-loaders'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const firstNameArray = ['S', 'm', 'a', 'r', 't']
  const lastNameArray = ['c', 'h', 'a', 'i', 'n']
  const jobArray = [
    'I',
    'n',
    'n',
    'o',
    'v',
    'a',
    't',
    'i',
    'o',
    'n',
    ' ',
    'T',
    'e',
    'c',
    'h',
    'n',
    'o',
    'l',
    'o',
    'g',
    'y',
    ' ',
    'T',
    'r',
    'a',
    'n',
    's',
    'f',
    'e',
    'r',
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 7000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={firstNameArray}
              idx={15}
            />
            <img src={LogoS} alt="developer" />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={lastNameArray}
              idx={20}
            />
            <br />
            <h2>
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray}
                idx={30}
              />
            </h2>
          </h1>
          <h2>AI Development / Blockchain Development / IoT Development</h2>
          <Link to="/contact" className="flat-button">
            CONTACT US
          </Link>
        </div>
        <Logo size="Large" />
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Home
